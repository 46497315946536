import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Substrate from 'components/Substrate';
import { questions } from 'data/test';
import { ISelectedAnswer } from 'types/interfaces';
import { UserRegions } from 'types/types';
import { device } from 'data/devices';
import DotsExit from 'assets/images/dots-left-exit.png';
import DotsCompleted from 'assets/images/dots-right-completed.png';

import Question from './Question';
import Result from './Result';
import Validation from './Validation';
import styles from './styles.module.scss';

interface IProps {
    userRegion: UserRegions;
    isOpen: boolean;
    closeTest(): void;
}

const Test: React.FC<IProps> = ({ closeTest, isOpen, userRegion }) => {
    const [showExitValidation, setShowExitValidation] = useState(false);
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [selectedAnswers, setSelectedAnswers] = useState<ISelectedAnswer[]>(
        []
    );
    const isMobile = useMediaQuery(device.mobile);

    const reset = () => {
        setIsTestCompleted(false);
        setShowExitValidation(false);
        setSelectedAnswers([]);
        setCurrentQuestion(1);
    };

    const selectAnswer = (selectedAnswer: ISelectedAnswer) => {
        const prevAnswer = selectedAnswers.findIndex(
            (ans) => ans.questionId === selectedAnswer.questionId
        );

        setSelectedAnswers((prev) => {
            if (prevAnswer < 0) {
                return [...prev, selectedAnswer];
            } else {
                return [
                    ...prev.slice(0, prevAnswer),
                    ...prev.slice(prevAnswer + 1),
                    selectedAnswer,
                ];
            }
        });

        if (currentQuestion < questions.length) {
            setCurrentQuestion((prev) => prev + 1);
        } else {
            setIsTestCompleted(true);
        }
    };

    return (
        <Substrate
            isOpen={isOpen}
            handleTransitionEnd={reset}
            close={
                isTestCompleted || currentQuestion === 1
                    ? closeTest
                    : () => setShowExitValidation(true)
            }
        >
            {isMobile && isTestCompleted && (
                <img
                    className={styles.dotsRightCompleted}
                    src={DotsCompleted}
                    alt="dots"
                />
            )}

            {isMobile && showExitValidation && (
                <img
                    className={styles.dotsLeftExit}
                    src={DotsExit}
                    alt="dots"
                />
            )}

            {isTestCompleted ? (
                <Result
                    userRegion={userRegion}
                    rightAnswers={
                        selectedAnswers.filter((answer) => answer.isCorrect)
                            .length
                    }
                    questionsNumber={questions.length}
                    onTryAgain={reset}
                />
            ) : showExitValidation ? (
                <Validation
                    onClose={closeTest}
                    onCancel={() => setShowExitValidation(false)}
                />
            ) : (
                <>
                    {questions.map((question) => (
                        <Question
                            key={question.id}
                            question={question}
                            questionsNumber={questions.length}
                            isCurrent={question.id === currentQuestion}
                            onAnswerSelectHandler={selectAnswer}
                        />
                    ))}
                </>
            )}
        </Substrate>
    );
};

export default Test;
