import React from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import Button from 'components/Button';
import Dots from 'assets/images/dots.png';

import Logo from 'assets/images/logo.svg';
import tableDots from 'assets/images/table_dots.png';
import { device } from 'data/devices';

import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    hideButton?: boolean;
    isVideos?: boolean;
    isLogoHidden?: boolean;
    handleTransitionEnd?(): void;
    close(): void;
}

const Substrate: React.FC<IProps> = ({
    handleTransitionEnd,
    close,
    isOpen,
    hideButton,
    isVideos,
    isLogoHidden,
    children,
}) => {
    const isTablet = useMediaQuery(device.tablet);

    const onTransitionEnd = (e: React.TransitionEvent) => {
        if (!isOpen && e.propertyName === 'transform' && handleTransitionEnd) {
            handleTransitionEnd();
        }
    };

    let button;
    if (!hideButton) {
        button = <Button className={styles.closeButton} color="light" variant="contained" onClick={close}>
            {!isTablet ? (
                <svg width="64" height="64" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.11 48.873L48.878 12 51 14.127 14.23 51l-2.12-2.127z" />
                    <path d="M50.89 48.873L14.122 12 12 14.127 48.77 51l2.12-2.127z" />
                </svg>
            ) : (
                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00012 9.41434L14.293 15.7072L15.7072 14.293L9.41434 8.00012L15.7072 1.70727L14.293 0.293062L8.00012 6.58591L1.70718 0.292969L0.292969 1.70718L6.58591 8.00012L0.292969 14.2931L1.70718 15.7073L8.00012 9.41434Z"
                        fill="white" />
                </svg>
            )}
        </Button>
    }

    let logo;
    if (!isLogoHidden) {
        logo = <img className={styles.logo} src={Logo} alt="logo" />;
    }

    return (
        <div
            onTransitionEnd={onTransitionEnd}
            className={classNames(styles.root, isOpen && styles.open, isVideos && styles.forVideos)}
        >
            {logo}
            <img
                className={styles.dotsLeft}
                src={!isTablet ? Dots : tableDots}
                alt="dots"
            />
            <img className={styles.dotsRight} src={Dots} alt="dots" />

            {children}

            {button}
        </div>
    );
};

export default Substrate;
