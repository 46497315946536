export const links = {
    main: {
        RUS: 'https://careers.veeam.ru/',
        CZE: 'https://cz.careers.veeam.com/',
        GLOBAL: 'https://careers.veeam.com/',
    },
    about: {
        RUS: 'https://careers.veeam.ru/company',
        CZE: 'https://cz.careers.veeam.com/company',
        GLOBAL: 'https://careers.veeam.com/about',
    },
    life: {
        RUS: 'https://careers.veeam.ru/about/support',
        CZE: 'https://cz.careers.veeam.com/about/support',
        GLOBAL: 'https://careers.veeam.com/life',
    },
    opportunities: {
        RUS: 'https://careers.veeam.ru',
        CZE: 'https://cz.careers.veeam.com',
        GLOBAL: 'https://careers.veeam.com/opportunities',
    },
    jobs: {
        RUS: 'https://jobs.smartrecruiters.com/ni/Veeam2/e51d1617-29db-412d-8f9b-e24e880f0cb2-technical-support-engineer?utm_source=global_landing&utm_medium=link&utm_campaign=q3_lp',
        ROU: 'https://jobs.smartrecruiters.com/oneclick-ui/company/Veeam2/publication/6668890f-7805-4e7e-bf92-2ba62eb93140?dcr_ci=Veeam2&dcr_id=DCRA1',
        AUS: 'https://jobs.smartrecruiters.com/oneclick-ui/company/Veeam2/publication/561516e0-e708-43a9-8ab2-47bd2d3af919?dcr_ci=Veeam2&dcr_id=DCRA1',
        USA: 'https://jobs.smartrecruiters.com/Veeam2/743999881327993-windows-support-engineer',
        GLOBAL: 'https://jobs.smartrecruiters.com/oneclick-ui/company/Veeam2/publication/1171ff57-d1df-411f-8584-1bee94a18418?dcr_ci=Veeam2&dcr_id=DCRA1',
        LATAM: 'https://jobs.smartrecruiters.com/Veeam2/743999881327993-windows-support-engineer',
    },
    signup: {
        RUS: 'https://tcs-rus.testograf.ru/',
        GLOBAL: 'https://flows.beamery.eu/veeamsoftware/talentcommunitytcs',
    },
    minAnswers: {
        RUS: 'https://tcs-rus.testograf.ru/',
        GLOBAL: 'https://flows.beamery.eu/veeamsoftware/talentcommunitytcs',
    },
    maxAnswers: {
        RUS: 'https://tcs-rus.testograf.ru/',
        GLOBAL: 'https://flows.beamery.eu/veeamsoftware/landing-tcs-stars',
    },
};
