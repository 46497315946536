import Header from 'components/Header';
import Main from 'components/Main';
import Test from 'components/Test';
import Videos from 'components/Videos';
import Faq from 'components/Faq';
import { useUserRegion } from 'hooks';
import React, { useState } from 'react';
import { EPages } from 'types/enums';

import styles from './styles.module.scss';

const App: React.FC = () => {
    const userRegion = useUserRegion();
    const [isTestOpen, setIsTestOpen] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [isFaqOpen, setIsFaqOpen] = useState(false);
    const [page, setPage] = useState(
        (sessionStorage.getItem('page') as EPages) || EPages.Landing
    );
    const [lastPage, setlastPage] = useState(
        (sessionStorage.getItem('page') as EPages) || EPages.Landing
    );
    // zoom prohibition
    document.addEventListener(
        'gesturestart',
        function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
        },
        { passive: false }
    );

    // for ios mobile safari
    window.scrollTo({ top: 0, left: 0 });

    const changePage = (to: EPages) => {
        setlastPage(page);
        sessionStorage.setItem('page', to);
        setPage(to);
    };

    return (
        <div className={styles.App}>
            <Test
                isOpen={isTestOpen}
                closeTest={() => setIsTestOpen(false)}
                userRegion={userRegion}
            />
            <Videos
                isOpen={isVideoOpen}
                closeVideo={() => setIsVideoOpen(false)}
            />
            <Faq
                isOpen={isFaqOpen}
                closeFaq={() => setIsFaqOpen(false)}
            />
            <Header changePage={changePage} userRegion={userRegion} />
            <Main
                openVideo={() => setIsVideoOpen(true)}
                openTest={() => setIsTestOpen(true)}
                openFaq={() => setIsFaqOpen(true)}
                page={page}
                lastPage={lastPage}
                changePage={changePage}
                userRegion={userRegion}
            />
        </div>
    );
};

export default App;
