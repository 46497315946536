import React, { useRef, useEffect, useState } from 'react';

import Substrate from 'components/Substrate';

import styles from './styles.module.scss';
import { IVideo } from './interfaces';
import { VIDEOS } from './constants';
import Button from '../Button';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../data/devices';
import Logo from '../../assets/images/logo.svg';

interface IProps {
    isOpen: boolean;
    closeVideo(): void;
}

const Videos: React.FC<IProps> = ({ isOpen, closeVideo }) => {
    const videoRef = useRef() as React.MutableRefObject<HTMLIFrameElement>;
    const [isFrameOpened, setIsFrameOpened] = useState(false);
    const isTablet = useMediaQuery(device.tablet);

    const updateSize = () => {
        const video = videoRef.current;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (video) {
            if (windowWidth >= windowHeight * 1.2) {
                const newWidth = (windowWidth / 100) * 67;
                video.width = newWidth.toString();
                video.height = ((newWidth * 9) / 16).toString();
            } else {
                const newWidth = (windowWidth / 100) * 100;
                video.width = newWidth.toString();
                video.height = ((newWidth * 9) / 16).toString();
            }
        }
    };

    useEffect(() => {
        updateSize();

        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                hideVideo();
            }
        }

        window.addEventListener('resize', updateSize);
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('resize', updateSize);
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const onClose = () => {
        hideVideo();
        closeVideo();
    };

    const getUrl = (video: IVideo):string => {
        return `https://www.youtube.com/embed/${video.id}?list=PLTUt4mO3fczlaT1EsB6Y64Nl1eMo27REo&index=${video.index}&autoplay=1&rel=0`;
    }

    const getVideo = (id: number) => {
        const video = VIDEOS[id];
        return (
            <div key={video.id} className={styles.video}>
                <div className={styles.videoTitle}>{video.title}</div>
                <div className={styles.videoCover} onClick={showVideo(video as IVideo)}>
                    <img src={require(`assets/images/videos/${video.id}.jpg`).default} alt={video.title} />
                </div>
            </div>
        );
    }

    const showVideo = (video: IVideo) => (e: any) => {
        const currentVideo = videoRef.current;
        if (currentVideo) {
            currentVideo.src = getUrl(video);
            setIsFrameOpened(true);
        }
    }

    const hideVideo = () => {
        const currentVideo = videoRef.current;
        if (currentVideo) {
            currentVideo.src = 'about:blank';
            setIsFrameOpened(false);
        }
    }

    const renderVideos = () => {
        return VIDEOS.map((video, index) => getVideo(index));
    }

    const logo = <img className={styles.logo} src={Logo} alt="logo" />;
    let closeFrameButton = <Button className={styles.closeButton} color="light" variant="contained" onClick={hideVideo}>
        {!isTablet ? (
            <svg width="64" height="64" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.11 48.873L48.878 12 51 14.127 14.23 51l-2.12-2.127z" />
                <path d="M50.89 48.873L14.122 12 12 14.127 48.77 51l2.12-2.127z" />
            </svg>
        ) : (
            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00012 9.41434L14.293 15.7072L15.7072 14.293L9.41434 8.00012L15.7072 1.70727L14.293 0.293062L8.00012 6.58591L1.70718 0.292969L0.292969 1.70718L6.58591 8.00012L0.292969 14.2931L1.70718 15.7073L8.00012 9.41434Z"
                      fill="white" />
            </svg>
        )}
    </Button>

    return (
        <Substrate isOpen={isOpen} close={onClose} hideButton={isFrameOpened} isVideos={true} isLogoHidden={true}>
            <div className={`${styles.root} ${ isFrameOpened ? styles.overflow : ''}`}>
                {logo}
                <div className={styles.videos}>
                    {renderVideos()}
                </div>
                <div onClick={hideVideo} className={`${styles.iframeContainer} ${ isFrameOpened ? styles.opened : ''}`}>
                    <iframe
                        className={styles.videoIframe}
                        title="video"
                        ref={videoRef}
                        width={1366}
                        height={768}
                        src="about:blank"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    {closeFrameButton}
                </div>
            </div>
        </Substrate>
    );
};

export default Videos;
