import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Logo from '../../assets/images/logo.svg';
import Button from 'components/Button';

interface IDialog {
    children: ReactNode | string;
    isOpen: boolean;
    setIsOpen(e: boolean): void;
}

const Dialog: FC<IDialog> = ({ children, isOpen, setIsOpen }) => {
    return (
        <div className={classNames(styles.dialog, isOpen && styles.open)}>
            <img className={styles.logo} src={Logo} alt="logo" />

            {children}

            <Button
                className={styles.closeButton}
                color="light"
                variant="contained"
                onClick={() => setIsOpen(false)}
            >
                <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.00012 9.41434L14.293 15.7072L15.7072 14.293L9.41434 8.00012L15.7072 1.70727L14.293 0.293062L8.00012 6.58591L1.70718 0.292969L0.292969 1.70718L6.58591 8.00012L0.292969 14.2931L1.70718 15.7073L8.00012 9.41434Z"
                        fill="#004550"
                    />
                </svg>
            </Button>
        </div>
    );
};

export default Dialog;
