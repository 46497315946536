import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProps {
    color?: 'default' | 'dark' | 'light' | 'yellow';
    variant?: 'outlined' | 'contained';
    size?: 'normal' | 'large';
    disabled?: boolean;
    href?: string;
    className?: string;
    onClick?(arg0?: any): void;
}

const Button: React.FC<IProps> = ({
    children,
    color = 'default',
    variant = 'outlined',
    size = 'normal',
    disabled,
    className,
    href,
    onClick,
}) => {
    const classes = classNames(
        styles.button,
        styles[color],
        styles[variant],
        styles[size],
        className
    );

    let component = (
        <button className={classes} disabled={disabled} onClick={onClick}>
            {children}
        </button>
    );

    if (href) {
        component = (
            <a
                target="_blank"
                rel="noreferrer"
                className={classNames(classes, disabled && styles.disabledLink)}
                href={href}
            >
                {children}
            </a>
        );
    }

    return component;
};

export default Button;
