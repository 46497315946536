import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import { EPages } from 'types/enums';

import styles from './styles.module.scss';
import { device } from '../../data/devices';

interface IProps {
    isCurrent: boolean;
    changePage: (to: EPages) => void;
}

const Landing: React.FC<IProps> = ({ changePage, isCurrent }) => {
    const isTablet = useMediaQuery(device.tablet);
    const isMobileLandscape = useMediaQuery(device.mobileLandscape);
    const isMobile = useMediaQuery(device.mobile);

    return (
        <div
            className={classNames(styles.landing, isCurrent && styles.current)}
        >
            <h1 className={styles.title}>Technical Customer Support</h1>
            <h3 className={styles.text}>
                {!isTablet
                    ? `This is a Technical Customer Support Team like no other.`
                    : ''}{' '}
                We work with industry-leading tech, award-winning products, the
                brightest minds in the business and the most exciting global
                clients. For you it means the chance to do more with your career
                than you ever thought possible.
            </h3>
            <button
                className={styles.button}
                onClick={() => changePage(EPages.Map)}
            >
                {isTablet || isMobileLandscape || isMobile
                    ? 'Tap to explore'
                    : 'Scroll to explore'}{' '}
                <svg fill="#004550" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 11.5L9 16.9l1 1.1 6.5-6.5L10.1 5l-1 1 5.4 5.5z" />
                </svg>
            </button>
        </div>
    );
};

export default Landing;
