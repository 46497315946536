import React from 'react';

import Button from 'components/Button';

import { results } from 'data/test';
import { links } from 'data/links';

import { JobsRegions } from 'types/types';

import styles from './styles.module.scss';

interface IProps {
    userRegion: string;
    rightAnswers: number;
    questionsNumber: number;
    onTryAgain(): void;
}

const Result: React.FC<IProps> = ({
    rightAnswers,
    questionsNumber,
    userRegion,
    onTryAgain,
}) => {
    const getResultIndex = () => {
        if (rightAnswers > 3 && rightAnswers < questionsNumber) {
            return 1;
        } else if (rightAnswers === questionsNumber) {
            return 2;
        } else {
            return 0;
        }
    };
    const resultIndex = getResultIndex();

    return (
        <div className={styles.result}>
            <span className={styles.rightAnswers}>
                {rightAnswers}/{questionsNumber}
            </span>
            <h1 className={styles.text}>{results[resultIndex].text}</h1>
            <div className={styles.buttonGroup}>
                {resultIndex === 0 && (
                    <Button
                        className={styles.tryAgain}
                        color="light"
                        onClick={onTryAgain}
                    >
                        Try again
                    </Button>
                )}
                {resultIndex === 1 && userRegion !== "RUS" && (
                    <Button
                        className={styles.button}
                        variant="contained"
                        color="light"
                        href={
                            links.jobs[userRegion as JobsRegions] ||
                            links.jobs.GLOBAL
                        }
                    >
                        {results[resultIndex].button}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Result;
