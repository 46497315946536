import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { device } from 'data/devices';

import Button from 'components/Button';
import MapImage from 'assets/images/map.png';
import Triangles from 'assets/images/triangles.png';
import Dots from 'assets/images/dots.png';
import Icon0 from 'assets/images/Icon0.png';
import Icon1 from 'assets/images/Icon1.png';
import Icon2 from 'assets/images/Icon2.png';
import Icon3 from 'assets/images/Icon3.png';
import Icon4 from 'assets/images/Icon4.png';
import Icon5 from 'assets/images/Icon5.png';
import Icon6 from 'assets/images/Icon6.png';
import Icon7 from 'assets/images/Icon7.png';

import { detailPages } from 'data/pages';

import { EPages } from 'types/enums';

import styles from './styles.module.scss';

interface IProps {
    currentPage: EPages;
    changePage(page: EPages): void;
    openTest(): void;
    openVideo(): void;
}

const Map: React.FC<IProps> = ({
    currentPage,
    changePage,
    openTest,
    openVideo,
}) => {
    const isMobile = useMediaQuery(device.mobile);
    const isTablet = useMediaQuery(device.tablet);

    useEffect(() => {
        const onWheel = (event: WheelEvent) => {
            const y = event.deltaY;
            if (y < 0 && currentPage === EPages.Map && !isTablet) {
                changePage(EPages.Landing);
            } else if (y > 0 && currentPage === EPages.Landing && !isTablet) {
                changePage(EPages.Map);
            }
        };

        window.addEventListener('wheel', onWheel);
        return () => {
            window.removeEventListener('wheel', onWheel);
        };
    }, [currentPage, changePage, isTablet]);

    return (
        <div className={classNames(styles.mapComponent)}>
            {['dotsLeft', 'dotsRight', 'dotsBottomLeft', 'dotsBottomRight'].map(
                (dots) => (
                    <img
                        className={classNames(
                            styles[dots],
                            styles[currentPage]
                        )}
                        key={dots}
                        src={Dots}
                        alt="dots"
                    />
                )
            )}

            <img
                className={classNames(styles.triangles, styles[currentPage])}
                src={Triangles}
                alt="triangles"
            />

            <div
                className={classNames(
                    styles.mapContainer,
                    styles[currentPage],
                    isMobile &&
                        ![EPages.Map, EPages.Landing].includes(currentPage) &&
                        styles.mapContainerHidden
                )}
            >
                <img
                    className={classNames(styles.mapImage, styles[currentPage])}
                    src={MapImage}
                    alt="Map"
                />

                {detailPages.map((page) => (
                    <Button
                        key={page.id}
                        className={classNames(
                            styles.button,
                            styles[page.id.toString()],
                            currentPage !== EPages.Map && styles.hidden
                        )}
                        color={page.page !== EPages.Apply ? 'dark' : 'yellow'}
                        variant="contained"
                        disabled={currentPage !== EPages.Map}
                        onClick={() => changePage(page.page)}
                    >
                        {page.title}
                    </Button>
                ))}

                <Button
                    className={classNames(
                        styles.button,
                        styles['6'],
                        currentPage !== EPages.Map && styles.hidden
                    )}
                    color="dark"
                    variant="contained"
                    disabled={currentPage !== EPages.Map}
                    onClick={openTest}
                >
                    Test your knowledge
                </Button>

                <Button
                    className={classNames(
                        styles.button,
                        styles['7'],
                        currentPage !== EPages.Map && styles.hidden
                    )}
                    color="dark"
                    variant="contained"
                    disabled={currentPage !== EPages.Map}
                    onClick={openVideo}
                >
                    Learning centre
                </Button>

                {[Icon0, Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7].map(
                    (Icon, index) => (
                        <img
                            key={Icon}
                            src={Icon}
                            className={classNames(styles.icon, styles[index])}
                            alt=""
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default Map;
