import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { device } from 'data/devices';

import Map from 'components/Map';
import Landing from 'components/Landing';
import DetailPage from 'components/DetailPage';
import Button from 'components/Button';
import Mouse from 'assets/images/mouse.svg';
import Back from 'assets/images/back.png';

import { detailPages } from 'data/pages';

import { EPages } from 'types/enums';
import { UserRegions } from 'types/types';

import styles from './styles.module.scss';

interface IProps {
    openTest(): void;
    openVideo(): void;
    openFaq(): void;
    page: EPages;
    changePage(to: EPages): void;
    userRegion: UserRegions;
    lastPage: EPages;
}

const Main: React.FC<IProps> = ({
    openTest,
    openVideo,
    openFaq,
    page,
    lastPage,
    changePage,
    userRegion,
}) => {
    const refMain = useRef<HTMLElement>(null);
    const isMobileLandscape = useMediaQuery(device.mobileLandscape);
    const isMobile = useMediaQuery(device.mobile);
    const isTablet = useMediaQuery(device.tablet);

    useEffect(() => {
        if (isMobileLandscape) {
            if (
                [
                    EPages.Landing,
                    EPages.Apply,
                    EPages.Do,
                    EPages.Grow,
                    EPages.Need,
                    EPages.Team,
                    EPages.Tech,
                ].includes(page)
            ) {
                refMain.current?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }

        if (page === EPages.Map) {
            if (isMobileLandscape && !isMobile && !isTablet) {
                refMain.current?.scrollTo({
                    top: 150,
                    left: 180,
                    behavior: 'smooth',
                });
            } else if (isMobile) {
                refMain.current?.scrollTo({
                    top: 0,
                    left: 350,
                    behavior: 'smooth',
                });
            }
        }
    }, [isMobile, isMobileLandscape, isTablet, page]);

    return (
        <main
            className={classNames(
                styles.main,
                page === EPages.Map && styles.map
            )}
            ref={refMain}
        >
            <div
                className={classNames(
                    page === EPages.Map &&
                        lastPage === EPages.Landing &&
                        styles.fingerUnderlay
                )}
            ></div>
            <div
                className={classNames(
                    page === EPages.Map &&
                        lastPage === EPages.Landing &&
                        styles.finger
                )}
            ></div>
            <Map
                currentPage={page}
                changePage={changePage}
                openTest={openTest}
                openVideo={openVideo}
            />

            <Landing
                changePage={changePage}
                isCurrent={page === EPages.Landing}
            />

            {detailPages.map((detailPage) => (
                <DetailPage
                    key={detailPage.id}
                    onClick={changePage}
                    userRegion={userRegion}
                    page={detailPage}
                    isCurrent={detailPage.page === page}
                    openFaq={openFaq}
                />
            ))}

            {!isTablet ? (
                <>
                    <Button
                        className={classNames(
                            styles.faqButton,
                            page !== EPages.Map &&
                            page !== EPages.Landing &&
                            styles.hidden
                        )}
                        disabled={page !== EPages.Map && page !== EPages.Landing}
                        color="light"
                        onClick={openFaq}
                    >
                        FAQ
                    </Button>
                </>
            ) : (
                ''
            )}

            <button
                className={classNames(
                    styles.up,
                    page !== EPages.Map && styles.hidden
                )}
                onClick={() => changePage(EPages.Landing)}
            >
                <img
                    className={styles.upImage}
                    src={
                        isMobile || isMobileLandscape || isTablet ? Back : Mouse
                    }
                    alt=""
                />
            </button>
        </main>
    );
};

export default Main;
