export enum EPages {
    Landing = 'landing',
    Map = 'map',
    Do = 'do',
    Tech = 'tech',
    Grow = 'grow',
    Team = 'team',
    Need = 'need',
    Apply = 'apply',
    Test = 'test',
}
