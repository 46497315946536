import React from 'react';
import classNames from 'classnames';

import Tablet from 'assets/images/tablet.png';
import Figure from 'assets/images/tablet_figure.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Need: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={Tablet} alt="" />
            <img
                className={classNames(
                    styles.figure,
                    isAnimate && styles.animate
                )}
                src={Figure}
                alt=""
            />
        </>
    );
};

export default Need;
