import React, { useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/Button';
import Logo from 'assets/images/logo.svg';

import { links } from 'data/links';
import { device } from 'data/devices';

import { UserRegions, MainRegions } from 'types/types';
import { EPages } from 'types/enums';

import styles from './styles.module.scss';
import Navigation from './Components/Navigation';
import MobileNavigation from './Components/MobileNavigation';

interface IProps {
    userRegion: UserRegions;
    changePage(to: EPages): void;
}

const Header: React.FC<IProps> = ({ changePage, userRegion }) => {
    const [showMainLink, setShowMainLink] = useState(false);
    const isTablet = useMediaQuery(device.tablet);

    return (
        <header className={styles.header}>
            <nav className={styles.nav}>
                <div className={styles.logoContainer}>
                    <button
                        className={styles.logoButton}
                        onClick={() => changePage(EPages.Landing)}
                        onMouseEnter={() => setShowMainLink(true)}
                        onMouseLeave={() => setShowMainLink(false)}
                    >
                        <img className={styles.logo} src={Logo} alt="logo" />
                    </button>
                    <div
                        role="button"
                        tabIndex={0}
                        className={classNames(
                            styles.mainLinkContainer,
                            showMainLink && styles.show
                        )}
                        onMouseEnter={() => setShowMainLink(true)}
                        onMouseLeave={() => setShowMainLink(false)}
                    >
                        <Button
                            color="dark"
                            variant="contained"
                            className={styles.mainLink}
                            href={
                                links.main[userRegion as MainRegions] ||
                                links.main.GLOBAL
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 12.5L15 7.1L14 6L7.5 12.5L13.9 19L14.9 18L9.5 12.5Z" />
                            </svg>{' '}
                            To&nbsp;
                            {(
                                links.main[userRegion as MainRegions] ||
                                links.main.GLOBAL
                            )
                                .replace('https://', '')
                                .replace('/', '')}
                        </Button>
                    </div>
                </div>
                {!isTablet ? (
                    <Navigation userRegion={userRegion} />
                ) : (
                    <MobileNavigation userRegion={userRegion} />
                )}
            </nav>
        </header>
    );
};

export default Header;
