import { IError, IOptions } from './interfaces';
import handleRequestErrors from './handleRequestErrors';

const sendRequest = async <T>(
    url: string,
    options: IOptions
): Promise<T | string> => {
    const response = await fetch(url, options);

    const { status }: { status: number } = response;

    let result;

    try {
        if (status >= 200 && status <= 299) {
            if (status === 204) {
                return 'no-content';
            }

            result = (await response.json()) as T;
        } else {
            const error = (await response.json()) as IError;

            handleRequestErrors(status, error);
        }
    } catch (error) {
        handleRequestErrors(status, error as IError);
    }

    return result as T | string;
};

export default sendRequest;
