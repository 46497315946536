export const LATAM = [
    'ATG',
    'AIA',
    'ARG',
    'ABW',
    'BRB',
    'BMU',
    'BOL',
    'BES',
    'BRA',
    'BHS',
    'BLZ',
    'CHL',
    'COL',
    'CRI',
    'CUW',
    'DMA',
    'DOM',
    'ECU',
    'GRD',
    'GTM',
    'GUY',
    'HND',
    'HTI',
    'JAM',
    'KNA',
    'CYM',
    'LCA',
    'MSR',
    'MEX',
    'NIC',
    'PAN',
    'PER',
    'PRI',
    'PRY',
    'SUR',
    'SLV',
    'SXM',
    'TCA',
    'TTO',
    'URY',
    'VCT',
    'VIR',
    'VGB',
    'VEN',
];
