import React from 'react';
import classNames from 'classnames';

import Keyboard from 'assets/images/keyboard.png';
import Tool from 'assets/images/keyboard_tool.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Tech: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={Keyboard} alt="" />
            <img
                className={classNames(styles.tool, isAnimate && styles.animate)}
                src={Tool}
                alt=""
            />
        </>
    );
};

export default Tech;
