import React from 'react';

import Button from 'components/Button';

import styles from './styles.module.scss';

interface IProps {
    onClose(): void;
    onCancel(): void;
}

const Validation: React.FC<IProps> = ({ onCancel, onClose }) => {
    return (
        <div className={styles.validation}>
            <h1 className={styles.text}>
                Are you sure you want to quit?
                <br />
                <small>You will lose your progress.</small>
            </h1>
            <div className={styles.buttonGroup}>
                <Button color="light" onClick={onClose}>
                    Yes
                </Button>
                <Button variant="contained" color="light" onClick={onCancel}>
                    No
                </Button>
            </div>
        </div>
    );
};

export default Validation;
