import ApplicationError from './applicationError';
import { IError } from './interfaces';

const handleRequestErrors = (status: number, error = {} as IError): void => {
    const { errorContent = {} as string, subStatus }: IError = error;

    const errorObject = new ApplicationError({
        status,
        subStatus,
        errorContent,
    });

    throw errorObject;
};

export default handleRequestErrors;
