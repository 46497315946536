import React from 'react';

import api from 'api';
import { ILocation } from 'types/interfaces';
import { UserRegions } from 'types/types';
import { LATAM } from "../data/geo";

// Get a user region
export const useUserRegion = (defaultRegion: UserRegions = 'GLOBAL') => {
    const [userRegion, setUserRegion] =
        React.useState<UserRegions>(defaultRegion);

    React.useEffect(() => {
        const locationApiUrl = process.env.REACT_APP_LOCATION_API_URL || '';

        api.get<ILocation>(locationApiUrl).then(
            (location) => {
                if (
                    ['RUS', 'CZE', 'AUS', 'ROU', 'USA'].includes(location.code)
                ) {
                    setUserRegion(location.code as UserRegions);
                } else if (LATAM.includes(location.code)) {
                    setUserRegion('LATAM');
                } else if (location.code === 'CAN') {
                    setUserRegion('USA');
                } else {
                    setUserRegion(defaultRegion);
                }
            },
            () => {
                setUserRegion(defaultRegion);
            }
        );
    }, [defaultRegion]);

    return userRegion;
};
