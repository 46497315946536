import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IArrowProps {
    type: '<' | '>';
    variant?: 'outlined' | 'contained';
    disabled?: boolean;
}

const Arrow: React.FC<IArrowProps> = ({
    type,
    variant = 'outlined',
    disabled = false,
}) => {
    return (
        <svg
            className={classNames(styles[variant], disabled && styles.disabled)}
            width="40"
            height="40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle r="18.5" transform="matrix(-1 0 0 1 20 20)" />
            {type === '<' ? (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="m17.033 19.841 7.197-7.197-1.795-1.796-8.993 8.993 8.993 8.993 1.796-1.795-7.198-7.198Z"
                />
            ) : (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.9971 19.8414L15.7998 27.0387L17.5956 28.8345L26.5888 19.8414L17.5956 10.8482L15.7998 12.6441L22.9971 19.8414Z"
                />
            )}
        </svg>
    );
};

interface IProps {
    type: 'back' | 'next';
    disabled?: boolean;
    onClick?(arg0?: any): void;
}

const NavigationButton: React.FC<IProps> = ({
    type,
    disabled = false,
    onClick,
    children,
}) => {
    return (
        <button className={styles.root} disabled={disabled} onClick={onClick}>
            {type === 'next' && <Arrow type=">" variant="contained" />}
            <span className={styles.text}>{children}</span>
            {type === 'back' && <Arrow type="<" />}
        </button>
    );
};

export default NavigationButton;
