import React from 'react';
import classNames from 'classnames';

import Phone from 'assets/images/phone.png';
import Face from 'assets/images/phone_face.png';
import FaceDot from 'assets/images/phone_face_dot.png';
import FaceEyes from 'assets/images/phone_face_eyes.png';
import Small1 from 'assets/images/phone_face_small1.png';
import Small2 from 'assets/images/phone_face_small2.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Team: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={Phone} alt="" />
            <img
                className={classNames(styles.face, isAnimate && styles.animate)}
                src={Face}
                alt=""
            />

            {[0, 1].map((faceEyes) => (
                <img
                    key={faceEyes}
                    className={classNames(
                        styles.faceEyes,
                        styles[faceEyes.toString()],
                        isAnimate && styles.animate
                    )}
                    src={FaceEyes}
                    alt=""
                />
            ))}
            {[0, 1, 2].map((faceDot) => (
                <img
                    key={faceDot}
                    className={classNames(
                        styles['faceDot' + faceDot.toString()],
                        isAnimate && styles.animate
                    )}
                    src={FaceDot}
                    alt=""
                />
            ))}

            <img
                className={classNames(
                    styles.small0,
                    isAnimate && styles.animate
                )}
                src={Small1}
                alt=""
            />
            <img
                className={classNames(
                    styles.small1,
                    isAnimate && styles.animate
                )}
                src={Small1}
                alt=""
            />
            <img
                className={classNames(
                    styles.small2,
                    isAnimate && styles.animate
                )}
                src={Small2}
                alt=""
            />
        </>
    );
};

export default Team;
