import React from 'react';
import classNames from 'classnames';

import ApplyImg from 'assets/images/apply.png';
import Heart from 'assets/images/apply_heart.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Apply: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={ApplyImg} alt="" />
            {[0, 1, 2, 3, 4].map((heart) => (
                <img
                    key={heart}
                    className={classNames(
                        styles.heart,
                        styles[heart.toString()],
                        isAnimate && styles.animate
                    )}
                    src={Heart}
                    alt=""
                />
            ))}
        </>
    );
};

export default Apply;
