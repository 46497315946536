export const faqTitle = 'Frequently Asked Questions (FAQs)';

export const faq = [
    {
        question: 'How to know if I am a good fit for Technical Customer Support?',
        answer: 'Good technical base as well as knowledge of a foreign language are equally important for a Veeam® Technical Support Engineer. Having a client-oriented approach is also necessary.',
    },
    {
        question: 'Will I be able to work in the Veeam Technical Customer Support department without work experience?',
        answer: 'Yes, you can work in the Technical Customer Support department without experience. The important things for us are your ability to speak a foreign language and the technical knowledge required by the job in question. Your desire to learn and develop will be a big advantage.',
    },
    {
        question: 'Do I have to speak English fluently to work at Veeam Technical Customer Support?',
        answer: 'Our department works with customers from different countries. So, we don’t only need people who are fluent in English but also people who are fluent in French, German, Spanish, Italian etc.',
    },
    {
        question: 'How many interview stages should I expect? How are the interviews are conducted?',
        answer: 'Interviews with candidates for Veeam Technical Customer Support are held in several stages: ' +
            '<ol>' +
            '<li>Preliminary candidate screening</li>' +
            '<li>Telephone interview with an HR representative</li>' +
            '<li>Hard and soft skill check</li>' +
            '<li>Interview</li>' +
            '<li>Language check</li>' +
            '<li>Feedback after the interview</li>' +
            '<li>Job offer</li>' +
            '</ol>',
    },
    {
        question: 'How should I prepare for a job interview in the Technical Customer Support department?',
        answer: '<ul>' +
            '<li>Get acquainted with the information about our company and products — it can be found on our website <a href="https://www.veeam.com>">www.veeam.com</a></li>' +
            '<li>Read the vacancy requirements.</li>' +
            '<li>If you’re not familiar with these topics, we recommend you research them.</li>' +
            '<li>Get ready for the interview in a foreign language.</li>' +
            '<li>Language check</li>' +
            '<li>Feedback after the interview</li>' +
            '<li>Job offer</li>' +
            '</ul><p>Sincere interest and a desire to learn are often more important to us than awards and titles!</p>',
    },
    {
        question: 'To whom does Veeam Software provide customer support?',
        answer: 'Veeam is a leading developer of backup, disaster recovery, and data management software for virtual, physical, and cloud-based workloads, as well as monitoring and planning of IT infrastructures. We have over 400,000 users worldwide. In general, these are mainly IT specialists at medium and large organizations. To them, we provide highly qualified technical support.'
    },
    {
        question: 'What is the work schedule like?',
        answer: 'The work schedule at Veeam is flexible. We have a five-day working week, an eight-hour working day with a one-hour lunch break. The time that you start your workday is adjustable (as agreed with your manager). In addition, some customer support teams work four days a week for 10 hours.'
    },
    {
        question: 'Will there be any training?',
        answer: 'A training course has been developed for new employees to learn about our complex products more easily and quickly. During this time, you’ll learn everything you need to know about Veeam products and how to provide expert customer support.'
    },
    {
        question: 'Are there any prospects for professional and career growth?',
        answer: 'Veeam is in a stage of active growth. For this reason, career development is quite fast and directly depends on the talents and efforts of the employee. There are three lines of customer support in our department. The first line is the starting point, nevertheless, all first-line specialists are technical experts and solve most of the incoming cases. We fill any emerging vacancies among the second-and third-line customer support specialists by promoting the most talented employees from the first and second lines respectively.'
    },
    {
        question: 'Where can I get acquainted with the lifestyle of the company?',
        answer: 'You can find out about Veeam corporate life on the career site, <a href="https://www.facebook.com/veeamcareers">Facebook</a> and <a href="https://www.instagram.com/veeamcareers/">Instagram</a>.'
    },
    {
        question: 'Can I apply for several job postings simultaneously?',
        answer: 'Yes, we encourage you to browse our many vacancies and select those that will be interesting for you.'
    },
    {
        question: 'Why are there always so many vacancies at Veeam? Is this due to staff turnover?',
        answer: 'We do indeed have many open positions. This is due to the company’s growth rather than to staff turnover. Our number of customers is growing very quickly, so we’re expanding the Customer Support department.'
    },
];
