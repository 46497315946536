import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { links } from 'data/links';
import { UserRegions, MainRegions } from 'types/types';

interface IProps {
    userRegion: UserRegions;
}

const Navigation: FC<IProps> = ({ userRegion }) => {
    return (
        <div>
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href={
                    links.about[userRegion as MainRegions] || links.about.GLOBAL
                }
            >
                About us
            </a>
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href={
                    links.life[userRegion as MainRegions] || links.life.GLOBAL
                }
            >
                Life at veeam
            </a>
            <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href={
                    links.opportunities[userRegion as MainRegions] ||
                    links.opportunities.GLOBAL
                }
            >
                Areas of interest
            </a>
        </div>
    );
};

export default Navigation;
