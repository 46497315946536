export const device = {
    tablet: {
        maxWidth: 1024,
        maxHeight: 1024,
    },
    mobile: {
        maxWidth: 580,
    },
    mobileLandscape: {
        maxWidth: 900,
    },
};
