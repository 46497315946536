import React from 'react';

import Substrate from 'components/Substrate';

import styles from './styles.module.scss';
import { faqTitle } from 'data/faq';
import { faq } from 'data/faq';
import Logo from '../../assets/images/logo.svg';

interface IProps {
    isOpen: boolean;
    closeFaq(): void;
}

const Faq: React.FC<IProps> = ({ isOpen, closeFaq }) => {

    const onClose = () => {
        closeFaq();
    };

    return (
        <Substrate isOpen={isOpen} isLogoHidden={true} close={onClose} >
            <div className={styles.root}>
                <img className={styles.logo} src={Logo} alt="logo" />

                <div className={styles.faqContentWrap}>
                    <p className={styles.faqPageTitle}>{ faqTitle }</p>
                    <div className={styles.faqQuestionsContainer}>
                        {faq.map((question, index) => (
                            <div key={index} className={styles.faqQuestionBlock}>
                                <p className={styles.faqQuestionTitle}>Q: {question.question}</p>
                                <div dangerouslySetInnerHTML={{ __html: 'A: ' + question.answer }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Substrate>
    );
};

export default Faq;
