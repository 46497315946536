import React from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import { IAnswer } from 'types/interfaces';

import styles from './styles.module.scss';

interface IProps {
    answer: IAnswer;
    onAnswerClickHandler(to: number | null): void;
}

const TestQuestion: React.FC<IProps> = ({ answer, onAnswerClickHandler }) => {
    return (
        <div className={classNames(styles.answerContainer, styles[answer.id])}>
            <Button
                onClick={() => onAnswerClickHandler(answer.id)}
                color="light"
                variant="outlined"
                size="large"
                className={styles.answerButton}
            >
                {answer.id}
            </Button>
            <span className={styles.answer}>{answer.answer}</span>
        </div>
    );
};

export default TestQuestion;
