import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/Button';
import NavigationButton from 'components/NavigationButton';
import Do from 'components/Elements/Do';
import Grow from 'components/Elements/Grow';
import Tech from 'components/Elements/Tech';
import Team from 'components/Elements/Team';
import Need from 'components/Elements/Need';
import Apply from 'components/Elements/Apply';

import { links } from 'data/links';

import { UserRegions, JobsRegions } from 'types/types';
import { EPages } from 'types/enums';
import { IPage } from 'types/interfaces';

import styles from './styles.module.scss';
import { device } from '../../data/devices';

interface IProps {
    userRegion: UserRegions;
    page: IPage;
    isCurrent: boolean;
    onClick(to: EPages): void;
    openFaq(): void;
}

const Detail: React.FC<IProps> = ({ onClick, openFaq, page, userRegion, isCurrent }) => {
    const isMobile = useMediaQuery(device.mobile);

    return (
        <div
            className={classNames(
                styles.detail,
                styles[page.position],
                styles[page.page],
                isCurrent && styles.current
            )}
        >
            {isMobile &&
                [Do, Grow, Tech, Team, Need, Apply].map(
                    (Picture, index) =>
                        page.id === index && (
                            <div
                                key={index}
                                className={classNames(
                                    styles.imageContainer,
                                    styles[page.position],
                                    styles[page.page],
                                    page.id === index && styles.visible
                                )}
                            >
                                <Picture isAnimate={isCurrent} />
                            </div>
                        )
                )}
            <article className={styles.article}>
                {!isMobile &&
                    [Do, Grow, Tech, Team, Need, Apply].map(
                        (Picture, index) =>
                            page.id === index && (
                                <div
                                    key={index}
                                    className={classNames(
                                        styles.imageContainer,
                                        styles[page.position],
                                        styles[page.page],
                                        page.id === index && styles.visible
                                    )}
                                >
                                    <Picture isAnimate={isCurrent} />
                                </div>
                            )
                    )}

                <div className={styles.text}>
                    <h5 className={styles.title}>{page.title}</h5>
                    <h2 className={styles.subtitle}>{page.subtitle}</h2>
                    {page.text.map((paragraph) => (
                        <p key={paragraph} className={styles.paragraph}>
                            {paragraph}
                        </p>
                    ))}
                </div>
            </article>

            <div className={styles.buttonGroup}>

                {userRegion !== "RUS" && (
                    <Button
                        color={page.page === EPages.Apply ? 'light' : 'yellow'}
                        variant="contained"
                        href={
                            links.jobs[userRegion as JobsRegions] ||
                            links.jobs.GLOBAL
                        }
                    >
                        {page.page === EPages.Apply ? 'Apply' : 'Apply now'}
                    </Button>
                )}

                <Button
                    color="light"
                    onClick={openFaq}
                >
                    FAQ
                </Button>

            </div>

            <div className={styles.navButtonGroup}>
                <span>
                    <NavigationButton
                        type="back"
                        onClick={() => onClick(EPages.Map)}
                    >
                        Back to map
                    </NavigationButton>
                </span>
                <NavigationButton
                    type="next"
                    disabled={page.page === EPages.Apply}
                    onClick={(e: Event) => {
                        (e.target! as HTMLButtonElement).blur();
                        if (page.next) {
                            onClick(page.next);
                        }
                    }}
                >
                    Next
                </NavigationButton>
            </div>
        </div>
    );
};

export default Detail;
