export const questions = [
    {
        id: 1,
        question: 'Which of the following is NOT a CPU socket:',
        answers: [
            {
                id: 1,
                answer: 'R3',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'LGA 1567',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'AM1',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'FM3',
                isCorrect: true,
            },
        ],
    },
    {
        id: 2,
        question: 'Which of the following is not affected by fragmentation:',
        answers: [
            {
                id: 1,
                answer: 'Hard disk drive',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'Solid state drive',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'Tape cartridge',
                isCorrect: true,
            },
            {
                id: 4,
                answer: 'USB stick',
                isCorrect: false,
            },
        ],
    },
    {
        id: 3,
        question:
            'Is it possible to run chkdsk /f against a system volume while logged on?',
        answers: [
            {
                id: 1,
                answer: 'Yes',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'Yes, but reboot is required',
                isCorrect: true,
            },
            {
                id: 3,
                answer: 'Yes, but it only performs scan and ignore /f, fix is performed on next restart',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'No',
                isCorrect: false,
            },
        ],
    },
    {
        id: 4,
        question: 'Where do you check the current disk load?',
        answers: [
            {
                id: 1,
                answer: 'Perfmon',
                isCorrect: true,
            },
            {
                id: 2,
                answer: 'Diskspd',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'Task manager',
                isCorrect: true,
            },
            {
                id: 4,
                answer: 'Disk properties',
                isCorrect: false,
            },
        ],
    },
    {
        id: 5,
        question:
            'Which RAID type can be used to substitute a backup solution?',
        answers: [
            {
                id: 1,
                answer: 'Raid 1',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'Raid 5',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'Raid 10',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'Raid is not a substitute for backup',
                isCorrect: true,
            },
        ],
    },
    {
        id: 6,
        question:
            'Checking ipconfig, you notice a 169.254.х.х IP address on your NIC. What could be the cause of it?',
        answers: [
            {
                id: 1,
                answer: 'DHCP server is not present in your network; NIC is set to receive IP address automatically',
                isCorrect: true,
            },
            {
                id: 2,
                answer: 'UDP ports 67, 68 are blocked by firewall',
                isCorrect: true,
            },
            {
                id: 3,
                answer: 'DHCP server is present, but static IP is configured',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'Regular workflow, DHCP server assigns IP address to a NIC',
                isCorrect: false,
            },
        ],
    },
    {
        id: 7,
        question:
            "Imagine you have a VMware VM running, you create a snapshot with quiescence and then manually delete the VM's base disks. In this case, the VM: ",
        answers: [
            {
                id: 1,
                answer: 'Immediately crashes',
                isCorrect: true,
            },
            {
                id: 2,
                answer: 'Continues running, notifies you that the previous VM state is lost',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'Goes into a paused state until you bring back the base disks',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'Is immediately removed from the inventory and leftovers are deleted from the datastore',
                isCorrect: false,
            },
        ],
    },
    {
        id: 8,
        question: 'What you call the highest hierarchy level of AD:',
        answers: [
            {
                id: 1,
                answer: 'Tree',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'Root',
                isCorrect: false,
            },
            {
                id: 3,
                answer: 'Schema',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'Forest',
                isCorrect: true,
            },
        ],
    },
    {
        id: 9,
        question:
            'Which utility is helpful for repairing an Exchange database in a “dirty shutdown” state?',
        answers: [
            {
                id: 1,
                answer: 'Ntdsutil',
                isCorrect: false,
            },
            {
                id: 2,
                answer: 'Eseutil',
                isCorrect: true,
            },
            {
                id: 3,
                answer: 'Chkdsk',
                isCorrect: false,
            },
            {
                id: 4,
                answer: 'Diskshadow',
                isCorrect: false,
            },
        ],
    },
];

export const results = [
    {
        text: 'Almost there! Try again!',
    },
    {
        text: 'Nice! Waiting for your application!',
        button: 'Apply now!',
    },
    {
        text: 'WOW! You are one of a few!',
    },
];
