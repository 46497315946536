import { EPages } from 'types/enums';
import { IPage } from 'types/interfaces';

export const detailPages: IPage[] = [
    {
        id: 0,
        page: EPages.Do,
        next: EPages.Grow,
        position: 'right',
        title: 'What you’ll do',
        subtitle:
            'There’s\u00A0no\u00A0one\u00A0way to\u00A0solve\u00A0a\u00A0problem',
        text: [
            'Here you’ll thrive in a role where solving customer problems comes naturally. You’ll draw on your deep technical expertise to answer questions from our clients’ IT teams - explaining solutions practically and helpfully to exceed all expectations. And we’ll empower you to learn even more - sharing knowledge and solutions with your friendly colleagues is all part of our ‘compete to win’ culture.',
            "No two problems are ever the same and that’s why we’ll give you all the freedom you need to think differently. We’re a growing entrepreneurial business, with plenty of infrastructures, set-ups, languages and teams. There's no one way to be Veeamazing at Veeam.",
        ],
    },
    {
        id: 1,
        page: EPages.Grow,
        next: EPages.Tech,
        position: 'right',
        title: 'How\u00A0you’ll\u00A0grow',
        subtitle: `There’s\u00A0no\u00A0one\u00A0way to\u00A0grow\u00A0your knowledge`,
        text: [
            'Within one or two years of working in our Technical Customer Support team, you’ll have learned more than you could have anywhere else. Why? Because we let you learn in the way that’s best for you. ',
            'Mentoring, training and coaching will help you to find your feet, take big challenges in your stride and perform at your best. And whether it’s learning additional skills, gaining new experience or taking the next step in your career, your chance to develop and grow yourself is boundless.',
            'We only promote from within, and we’ll set you up to win because we want you to progress as far as possible. If you show the dedication and drive, we won’t hold you back. We’ll always find ways to encourage you to grow!',
        ],
    },
    {
        id: 2,
        page: EPages.Tech,
        next: EPages.Team,
        position: 'left',
        title: 'The tech you’ll work with',
        subtitle: 'There’s\u00A0no\u00A0one\u00A0way to\u00A0be\u00A0involved',
        text: [
            'Working in our Technical Customer Support team at Veeam means getting hands-on with unique setups and environments. It also means building your expertise by working with different industries and an award-winning product which is constantly evolving. ',
            'You’ll boost your knowledge by immersing yourself in industry leading technologies like VMware vSphere, Microsoft Hyper-V, Microsoft Exchange server administration, SQL and Oracle Database engines scripting in many languages, and much more. From gaming to finance, space to healthcare, with Veeam, there’s no one architecture. And it’s all yours to absorb and explore.',
        ],
    },
    {
        id: 3,
        page: EPages.Team,
        next: EPages.Need,
        position: 'right',
        title: 'The team',
        subtitle: 'There’s\u00A0no\u00A0one\u00A0way to\u00A0belong',
        text: [
            'Collaboration is the name of the game. We’re a real mix of curious minds and perspectives - introverts, extroverts and everyone in between. We work together, bounce ideas and solve problems in a way that pushes and brings out the best in each other. That’s the spirit of Veeam. Join us and we’ll encourage you to come together, have fun with and learn from, some of the best minds in the industry. ',
        ],
    },
    {
        id: 4,
        page: EPages.Need,
        next: EPages.Apply,
        position: 'left',
        title: 'What you’ll need',
        subtitle: 'There’s\u00A0no\u00A0one\u00A0way to\u00A0be\u00A0unique',
        text: [
            'Just like us, you’re unique. You’re that rare blend of technical brilliance and warm customer service skills. You love helping people out by solving challenging problems they’ve already tried to solve. Nothing feels better than putting the pieces of a complicated puzzle together and successfully finding that solution. You’ll be analytical, understanding and open minded. You’ll already have experience in a similar role and will be ready to find out why we’re voted the best place to work in IT.',
        ],
    },
    {
        id: 5,
        page: EPages.Apply,
        position: 'left',
        title: 'Apply\u00A0now',
        subtitle:
            'There’s\u00A0no\u00A0one\u00A0way to\u00A0do\u00A0what\u00A0you\u00A0love',
        text: [
            "If technology makes you tick and you love taking on extra responsibility, Veeam is the place for you. Join us in Technical Customer Support, where there's no one way to solve complex IT challenges.",
        ],
    },
];
