import React from 'react';
import classNames from 'classnames';

import FlowerBottom from 'assets/images/flower_bottom.png';
import Flower from 'assets/images/flower_full.png';
import Flare from 'assets/images/flare.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Grow: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={FlowerBottom} alt="" />
            <img
                className={classNames(
                    styles.flower,
                    isAnimate && styles.animate
                )}
                src={Flower}
                alt=""
            />
            <img
                className={classNames(
                    styles.flare,
                    isAnimate && styles.animate
                )}
                src={Flare}
                alt=""
            />
        </>
    );
};

export default Grow;
