import React from 'react';
import classNames from 'classnames';

import Laptop from 'assets/images/laptop.png';
import Left from 'assets/images/laptop_coin_left.png';
import Right from 'assets/images/laptop_coin_right.png';

import { IElements } from 'types/interfaces';

import styles from './styles.module.scss';

const Do: React.FC<IElements> = ({ isAnimate }) => {
    return (
        <>
            <img className={styles.image} src={Laptop} alt="" />
            <img
                className={classNames(
                    styles.coinLeft,
                    isAnimate && styles.animate
                )}
                src={Left}
                alt=""
            />
            <img
                className={classNames(
                    styles.coinRight,
                    isAnimate && styles.animate
                )}
                src={Right}
                alt=""
            />
        </>
    );
};

export default Do;
