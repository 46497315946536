import React from 'react';
import classNames from 'classnames';

import Answer from '../Answer';

import { IQuestion, ISelectedAnswer } from 'types/interfaces';

import styles from './styles.module.scss';

interface IProps {
    isCurrent: boolean;
    question: IQuestion;
    questionsNumber: number;
    onAnswerSelectHandler(obj: ISelectedAnswer): void;
}

const TestQuestion: React.FC<IProps> = ({
    isCurrent,
    question,
    questionsNumber,
    onAnswerSelectHandler,
}) => {
    const selectAnswer = (answer: number) => {
        onAnswerSelectHandler({
            questionId: question.id,
            answerId: answer,
            isCorrect: question.answers[answer - 1].isCorrect,
        });
    };

    return (
        <div
            className={classNames(
                styles.testQuestion,
                isCurrent && styles.current
            )}
            id={isCurrent ? 'current' : ''}
        >
            <span className={styles.questionNumber}>
                Questions <span>{question.id}</span>/{questionsNumber}
            </span>

            <h1 className={styles.question}>{question.question}</h1>

            <div className={styles.answersContainer}>
                {question.answers.map((answer) => (
                    <Answer
                        key={answer.id}
                        answer={answer}
                        onAnswerClickHandler={selectAnswer}
                    />
                ))}
            </div>
        </div>
    );
};

export default TestQuestion;
