import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { links } from 'data/links';
import { JobsRegions, MainRegions, UserRegions } from 'types/types';
import Button from 'components/Button';
import Dialog from 'components/Dialog';

interface IProps {
    userRegion: UserRegions;
}

const MobileNavigation: FC<IProps> = ({ userRegion }) => {
    const [isShowNavigation, setIsShowNavigation] = useState<boolean>(false);

    return (
        <div>
            <Button
                onClick={() => setIsShowNavigation(true)}
                className={styles.burger}
            >
                <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M20 14V16H0V14H20ZM20 7V9H0V7H20ZM20 0V2H0V0H20Z"
                        fill="#004550"
                    />
                </svg>
            </Button>

            <Dialog isOpen={isShowNavigation} setIsOpen={setIsShowNavigation}>
                <div className={styles.items}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            links.about[userRegion as MainRegions] ||
                            links.about.GLOBAL
                        }
                    >
                        About us
                    </a>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            links.life[userRegion as MainRegions] ||
                            links.life.GLOBAL
                        }
                    >
                        Life at veeam
                    </a>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            links.opportunities[userRegion as MainRegions] ||
                            links.opportunities.GLOBAL
                        }
                    >
                        Areas of interest
                    </a>
                    <div className={styles.buttonGroup}>

                        {userRegion !== "RUS" && (
                            <Button
                                href={
                                    links.jobs[userRegion as JobsRegions] ||
                                    links.jobs.GLOBAL
                                }
                                color="yellow"
                                variant="contained"
                            >
                                Apply now
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default MobileNavigation;
