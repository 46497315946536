import { IVideo } from './interfaces';

export const VIDEOS: Array<IVideo> = [
    {
        id: 'ZIDbpB4rgnY',
        index: 5,
        title: 'Troubleshooting Veeam Backup for Office 365',
    },
    {
        id: 'V89Pw2yAPHk',
        index: 6,
        title: 'A Day in Life of Veeam Support Engineer',
    },
    {
        id: 'L2KQa-dqcl8',
        index: 4,
        title: 'Building World-Class Veeam Technical Support',
    },
    {
        id: 'ouWM-AyiNxM',
        index: 2,
        title: 'VMware Integration Troubleshooting',
    }
];
