import sendRequest from './sendRequest';
import { IOptions } from './interfaces';

const headers = {
    'Content-Type': 'application/json;charset=utf-8',
};

const api = {
    get<T>(url: string, options?: IOptions): Promise<T> {
        const defaultOptions = {
            headers,
            ...options,
        };

        return sendRequest(url, defaultOptions) as Promise<T>;
    },

    post<T>(url: string, body: any, options?: IOptions): Promise<T> {
        const defaultOptions = {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
            ...options,
        };

        return sendRequest(url, defaultOptions) as Promise<T>;
    },

    put<T>(url: string, body: any, options?: IOptions): Promise<T> {
        const defaultOptions = {
            method: 'PUT',
            headers,
            body: JSON.stringify(body),
            ...options,
        };

        return sendRequest(url, defaultOptions) as Promise<T>;
    },
};

export default api;
